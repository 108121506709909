import React from "react";
import Timeline from "../components/Timeline";

export default function Journey() {
  return (
    <>
      <Timeline />
    </>
  );
}
