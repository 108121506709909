import React from "react";
import Professionals from "../components/Professionals";

export default function HealthProfessionals() {
  return (
    <>
      <Professionals />
    </>
  );
}
