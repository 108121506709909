import React from "react";
import ClientSupport from "../components/ClientSupport";

export default function Enquire() {
  return (
    <>
      <ClientSupport />
    </>
  );
}
